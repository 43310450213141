<nav class="navbar navbar-expand-lg bg-light p-0">
    <div class="d-flex justify-content-start align-items-center py-2 ml-4 content-logo">
        <div class="left_area mr-5 position-relative">
            <a class="text-decoration-none">
                <!-- <img class="img-fluid"
                    src="http://www.forus.cl/info/files/fotos/fotosProyecto/intranet/logo/LOGO-139.png" alt="Logo"> -->
                <img class="img-fluid"
                    src="assets/img/logo.png" alt="Logo">
            </a>
        </div>
        <label class="mt-0 mb-0 ml-0 mr-5" for="check">
            <i id="sidebar_btn text-dark">
                <svg viewBox="0 0 16 16" class="bi bi-menu-button icon-header sidebar-btn" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-8zM14 7H2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zM2 6a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H2z" />
                    <path fill-rule="evenodd"
                        d="M15 11H1v-1h14v1zM2 12.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
                    <path
                        d="M7.823 2.823l-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0z" />
                </svg>
            </i>
        </label>
    </div>
</nav>