<section class="container content-login h-100 animated fadeIn pb-5 d-flex align-items-center">

    
    <div *ngIf="isLoadingClient" class="d-flex justify-content-center animated fadeIn fast w-100">
        <div class="spinner-border spinner spinner-init" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <!-- *ngIf="!isLoadingClient" -->
    <div  *ngIf="!isLoadingClient"  class="w-100">
        <div *ngIf="!isError; else elseError" 
            class="content-form mx-auto shadow rounded p-4">
            <form >
                <div class="form-group">
                    <input type="text" class="form-control" id="exampleFormControlInput1" 
                        placeholder="Ingrese código de verificación" #pass>
                </div>
                <div class="form-group m-0">
                    <button class="btn btn-primary-app text-light" type="button"
                        (click)="onClickSendPass(pass.value);">
                        Enviar
                    </button>
                </div>
            </form>            
        </div>
        <ng-template #elseError>
            <div class="alert m-0 content-form mx-auto shadow rounded p-4" role="alert"
            [ngClass]="alert">
                {{ msgWarning }}
            </div>
        </ng-template>        
    </div>

</section>
