import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  public showAlert(title: string, msg: string, isError: boolean): void {
    Swal.fire({
      icon: (isError) ? "error" : "warning",
      title: title,
      text: `${msg}`,
      width: "25rem",
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'btn-primary-app',
        cancelButton: 'bg-secondary'
      }
    });
  }

  public showAlertSuccess(msg: string): void {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `${msg}`,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timer: 3000
    });
  }
}
