import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComunicationService {

  message: any;
  private sendMessageSubject = new Subject<any>();
  sendMessageObservable = this.sendMessageSubject.asObservable();

  constructor() { }

  public sendMessage(message: any){
    this.message = message;
    this.sendMessageSubject.next(message);
  }

  public setSessionStorage(name: string, object: any): void{
    sessionStorage.setItem(name, JSON.stringify(object));
  }

  public getSessionStorage(name: string): any{
    let _sessionStorage = JSON.parse(sessionStorage.getItem(name));
    return _sessionStorage;
  }

  public deletedSessionStorage(name: string): void{
    sessionStorage.removeItem(name);
  }
}
