import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/helpers/utility.service';
import { Cliente } from 'src/app/models/cliente';
import { ComunicationService } from 'src/app/services/comunication.service';
import { QuiebreService } from 'src/app/services/quiebre.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isLoadingClient: boolean;
  isError: boolean;
  msgWarning: string;
  alert: string;
  token: string;
  cliente: Cliente;

  constructor(private route: ActivatedRoute, private comunicacionService: ComunicationService,
    private utility: UtilityService, private router: Router, private service: QuiebreService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let tokenParam: string = params['token'];

      let tokenSession = this.comunicacionService.getSessionStorage("token");
      if(tokenParam || tokenSession) {
        this.emptyParamError();    
        this.token = tokenParam ? tokenParam: tokenSession;
        this.validQuiebre();
      } else {
        this.setParamError("Verifique su correo y pinche el enlace nuevamente", "alert-warning");
      }
    });
  }

  private validQuiebre(): void {
    this.isLoadingClient = true;
    this.service.validQuiebre(this.token).subscribe(response => {
      this.isLoadingClient = false;
      if(response > 0) {
        this.getDatosCliente(this.token);
      } else {
        this.setParamError("Token invalido, verifique su correo y pinche el enlace nuevamente", "alert-warning");
      }
    });
  }

  private getDatosCliente(token: string): void {
    this.isLoadingClient = true;
    this.service.getDatosCliente(token).subscribe(response => {      
      this.isLoadingClient = false;
      this.cliente = response;
      if(this.comunicacionService.getSessionStorage("token")) {
        this.goToForm();
      }
    }, err => {
      this.isLoadingClient = false;
      this.showError(err);
    }); 
  }

  public onClickSendPass(pass: string): void {
    if(pass) {
      if(pass == this.cliente.pass) {
        this.comunicacionService.setSessionStorage("token", this.token);
        this.goToForm();
      } else {
        this.utility.showAlert("Advertencia", "Código incorrecto, verfique e intente nuevamente", false);
      }
    } else {
      this.utility.showAlert("Advertencia", "Debe ingresar su clave de verificación", false);
    }    
  }

  private showError(err: any): void {
    switch(err.status) {        
      case 400:
      break;

      case 404:
        this.setParamError("Su solicitud ya fue ingresada", "alert-info");
      break;

      case 409:
        this.setParamError(err.error.error, "alert-warning");
      break;

      case 500:
        this.setParamError("Ha ocurrido un problema interno, intente mas tarde", "alert-danger");
      break;
    }
  }

  private setParamError(msg: string, alert: string): void {
    this.isError = true;
    this.msgWarning = msg;
    this.alert = alert;
  }

  private emptyParamError(): void {
    this.isError = false;
    this.msgWarning = null;
    this.alert = null;
  }

  public goToForm(){
    this.router.navigate(['/form']);
  }
}
