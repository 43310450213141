import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidClient } from '../models/valid-client';

@Injectable({
  providedIn: 'root'
})
export class QuiebreService {

  private endpointLocalHost = "http://localhost:8080/ali-reportes";
  private endpointDev = "http://a3511733113d94803830fb5c06866eb1-14b7e502daccab01.elb.us-east-1.amazonaws.com/ali/ali-reportes";
  private endpoint = "https://api-reporteria.ali.forus-sistemas.com/ali/ali-reportes";

  constructor(private http: HttpClient) { }

  public getBanks() : Observable<any> {
    return this.http.get<any>(`${this.endpoint}/bancos`).pipe(
      map(response => {  
        return response.data; 
      })
    );
  }

  public getDatosCliente(token: string) : Observable<any> {
    return this.http.get<any>(`${this.endpoint}/datos-cliente-quiebre?token=${token}`).pipe(
      map(response => {  
        return response.data; 
      })
    );
  }

  public validQuiebre(token: string) : Observable<any> {
    return this.http.get<any>(`${this.endpoint}/valid-quiebre?token=${token}`).pipe(
      map(response => {  
        return response.data; 
      })
    );
  }

  public setClientValidFormQuiebre(validClient: ValidClient, token: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(`${this.endpoint}/set-client-form-quiebre?token=${token}`, validClient, {headers: headers}).pipe(
      map(response => {  
        return response.data; 
      })
    );
  }
}
