import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'src/app/helpers/utility.service';
import { Banco } from 'src/app/models/banco';
import { Cliente } from 'src/app/models/cliente';
import { ValidClient } from 'src/app/models/valid-client';
import { ComunicationService } from 'src/app/services/comunication.service';
import { QuiebreService } from 'src/app/services/quiebre.service';

@Component({
  selector: 'app-form-quiebre',
  templateUrl: './form-quiebre.component.html',
  styleUrls: ['./form-quiebre.component.css']
})
export class FormQuiebreComponent implements OnInit {

  bancos: Banco[] = [];
  cliente: Cliente;
  isValidRut: boolean = true;
  isLoadingClient: boolean;
  isLoadSave: boolean;
  isSave: boolean;
  validClient: ValidClient = new ValidClient();
  //@ViewChild('typeDispatch', { static: false }) typeDispatch: ElementRef;
  error: any;
  isError: boolean;
  msgWarning: string;
  alert: string;
  token: string;

  constructor(private service: QuiebreService, private utility: UtilityService,
    private comunicacionService: ComunicationService) { }

  ngOnInit(): void {
    let token = this.comunicacionService.getSessionStorage("token");

    if(token) {
      this.token = token;
      this.emptyParamError();
      this.getBanks();
      this.getDatosCliente(token);
    } else {
      this.setParamError("Verifique su correo y pinche el enlace nuevamente", "alert-warning");
    } 
  }

  private getBanks(): void {
    this.service.getBanks().subscribe(response => {
      this.bancos = response;
    });
  }

  private getDatosCliente(token: string): void {
    this.isLoadingClient = true;
    this.service.getDatosCliente(token).subscribe(response => {
      this.isLoadingClient = false;
      this.cliente = response;
    }, err => {
      this.isLoadingClient = false;
      this.showError(err);
    }); 
  }

  public onBlurRut(e: any) {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    let rutCompleto: string = e.value;

    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto )) {
      this.isValidRut = false;
    }
        
    let tmp 	= rutCompleto.split('-');
    let digv	= tmp[1]; 
    let rut 	= +tmp[0];
    if ( digv == 'K' ) {
      digv = 'k';
    }      
    this.isValidRut = (this.validDigitoVerificador(rut) == digv) ? true : false;

  }

  public validDigitoVerificador(T: number) {
    let M=0,S=1;
      for(;T;T=Math.floor(T/10))
        S=(S+T%10*(9-M++%6))%11;
      return S?S-1:'k';
  }

  public onFocusRut(): void {
    this.isValidRut = true;
  }

  public onClickGuardarQuiebre(): void {
    if(this.isValidRut) {
      this.isLoadSave = true;
      this.error = null;
      this.validClient.observacion = "";
      this.validClient.orden = this.cliente.orden;
      this.validClient.dtId = this.cliente.dtId;

      let rut = (this.validClient.rut) ? this.validClient.rut.split("-") : "";
      this.validClient.rut = (rut) ? rut[0] + rut[1] : "";

      this.service.setClientValidFormQuiebre(this.validClient, this.token).subscribe(response => {
        this.isLoadSave = false;
        this.isSave = true;
        this.setParamError(response, "alert-success");
        this.comunicacionService.deletedSessionStorage("token");
      }, err => {
        this.isLoadSave = false;
        switch(err.status) {        
          case 400:
            this.error = err.error.error;
          break;
  
          case 404:
            this.utility.showAlert("Advertencia", "Recursos no encontrados", false);
          break;
  
          case 409:
            this.error = err.error.error;
            this.setParamError(this.error, "alert-warning");
          break;
  
          case 500:
            this.utility.showAlert("ERROR", "Ocurrio un error en el servidor, intente más tarde.", true);
          break;
        }
      });

    }
  }

  private showError(err: any): void {
    switch(err.status) {        
      case 400:
        this.error = err.error.error;
      break;

      case 404:
        this.utility.showAlert("Advertencia", "Recursos no encontrados", false);
      break;

      case 409:
            this.error = err.error.error;
            this.setParamError(this.error, "alert-warning");
          break;

      case 500:
        this.utility.showAlert("ERROR", "Ocurrio un error en el servidor, intente más tarde.", true);
      break;
    }
  }

  private setParamError(msg: string, alert: string): void {
    this.isError = true;
    this.msgWarning = msg;
    this.alert = alert;
  }

  private emptyParamError(): void {
    this.isError = false;
    this.msgWarning = null;
    this.alert = null;
  }
}
