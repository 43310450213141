export class ValidClient {
    nombre: string;
    apellido: string;
    rut: string;
    orden: string;
    cuenta: string;
    tipoCta: string;
    banco: string;
    dtId: number;
    observacion: string;
}
