import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormQuiebreComponent } from './components/form-quiebre/form-quiebre.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path:'', pathMatch: 'full', component: LoginComponent },
  { path:'form',  component: FormQuiebreComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
