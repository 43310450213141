<section class="container content-info-client animated fadeIn pb-5">

    <div *ngIf="isLoadingClient" class="d-flex justify-content-center animated fadeIn fast w-100">
        <div class="spinner-border spinner spinner-init" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div *ngIf="!isLoadingClient">
        <div *ngIf="!isError; else elseError" >
            <div class="row">
                <div class="col-md-12">
                    <div class="form-row bg-form shadow rounded p-3">

                        <div *ngIf="isLoadingClient else elseClient"
                            class="d-flex justify-content-center w-100 animated fadeIn fast">
                            <div class="spinner-border spinner position-relative" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <ng-template #elseClient>
                            <div class="col-md-4">
                                <div class="mb-1">
                                    <span><b>Nombre Cliente:</b></span>
                                    <span class="ml-2">{{ cliente?.nombres }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>Rut:</b></span>
                                    <span class="ml-2">{{ cliente?.rut }}</span>
                                </div>                                
                                <div class="mb-1">
                                    <span><b>Teléfono:</b></span>
                                    <span class="ml-2">{{ cliente?.fono }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>Email:</b></span>
                                    <span class="ml-2">{{ cliente?.email }}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-1">
                                    <span><b>Fecha Compra:</b></span>
                                    <span class="ml-2">{{ cliente?.fechaCompra }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>N° de Transacción:</b></span>
                                    <span class="ml-2">{{ cliente?.dtId }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>Medio de Pago:</b></span>
                                    <span class="ml-2">{{ cliente?.mediopago }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>N° de Orden:</b></span>
                                    <span class="ml-2">{{ cliente?.orden }}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-1">
                                    <span><b>Tienda:</b></span>
                                    <span class="ml-2">{{ cliente?.tienda }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>Dirección:</b></span>
                                    <span class="ml-2">{{ cliente?.direccion }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>Comuna:</b></span>
                                    <span class="ml-2">{{ cliente?.comuna }}</span>
                                </div>
                                <div class="mb-1">
                                    <span><b>Región:</b></span>
                                    <span class="ml-2">{{ cliente?.region }}</span>
                                </div>
                            </div>
                        </ng-template>

                    </div>
                </div>
            </div>

            <div *ngIf="!isSave else elseSave">

                <div class="shadow rounded my-5 p-4 animated fadeIn">
                    <form>
                        <div class="form-group row">
                            <label for="region" class="col-sm-3 col-form-label"><b>Nombre</b></label>
                            <div class="col-sm-9 d-flex flex-column align-items-center">
                                <input type="text" class="form-control form-control" id="nombre-tarjeta" #nombreTarj 
                                name="nombre" [(ngModel)]="validClient.nombre">
                                <div class="alert alert-danger py-1 px-3 mt-1 mb-2 w-100" *ngIf="error && error.nombre">
                                    {{error.nombre}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="comuna" class="col-sm-3 col-form-label"><b>Apellido</b></label>
                            <div class="col-sm-9 d-flex flex-column align-items-center">
                                <input type="text" class="form-control form-control" id="apellido-tarjeta" #apellidoTarj
                                name="apellido" [(ngModel)]="validClient.apellido" >
                                <div class="alert alert-danger py-1 px-3 mt-1 mb-2 w-100" *ngIf="error && error.apellido">
                                    {{error.apellido}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="sucursal" class="col-sm-3 col-form-label"><b>Rut</b></label>
                            <div class="col-sm-9 d-flex flex-column align-items-center">
                                <input type="text" class="form-control form-control" id="rut-tarjeta" #rutTarj 
                                  placeholder="Rut sin puntos (Ej: 12345678-9)" name="rut" [(ngModel)]="validClient.rut"
                                  (blur)="onBlurRut($event.target)" (focus)="onFocusRut()">
                                <div *ngIf="!isValidRut" class="alert alert-danger py-1 px-3 mt-1 mb-2 w-100" role="alert">RUT no válido (Ej: 12345678-9)</div>
                                <div class="alert alert-danger py-1 px-3 mt-1 mb-2 w-100" *ngIf="error && error.rut">
                                    {{error.rut}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="direccion" class="col-sm-3 col-form-label"><b>Banco</b></label>
                            <div class="col-sm-9 d-flex flex-column align-items-center">
                                <select class="custom-select custom-select" id="banco" #banco
                                name="banco" [(ngModel)]="validClient.banco">
                                    <!-- <option value="" [selected]="1==1">Selecione...</option> -->
                                    <option *ngFor="let banco of bancos; let index=index" [value]="banco.nombreBanco">{{banco.nombreBanco}}</option>
                                </select>
                                <div class="alert alert-danger py-1 px-3 mt-1 mb-2 w-100" *ngIf="error && error.banco">
                                    {{error.banco}}
                                </div>
                            </div>

                        </div>
                        <div class="form-group row">
                            <label for="nombre-destinatatio" class="col-sm-3 col-form-label"><b>Tipo Cuenta</b></label>
                            <div class="col-sm-9 d-flex flex-column align-items-center">
                                <select class="custom-select custom-select" id="tipo-cuenta" #tipoCta
                                    name="tipoCta" [(ngModel)]="validClient.tipoCta">
                                    <option value="" selected>Selecione...</option>
                                    <option value="Cuenta Corriente">Cuenta Corriente</option>
                                    <option value="Cuenta Vista">Cuenta Vista</option>
                                    <option value="Cuenta de Ahorro">Cuenta de Ahorro</option>
                                </select>
                                <div class="alert alert-danger py-1 px-3 mt-1 mb-2 w-100" *ngIf="error && error.tipoCta">
                                    {{error.tipoCta}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="fono-destinatatio" class="col-sm-3 col-form-label"><b>Número Cuenta Bancaria</b></label>
                            <div class="col-sm-9 d-flex flex-column align-items-center">
                                <input type="text" class="form-control form-control" id="num-cta" #cta
                                  name="cuenta" [(ngModel)]="validClient.cuenta">
                                <div class="alert alert-danger py-1 px-3 mt-1 mb-2 w-100" *ngIf="error && error.cuenta">
                                    {{error.cuenta}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <div class="col-sm-10">
                                <button type="button" class="btn btn-primary-app text-light"
                                    (click)="onClickGuardarQuiebre()" [disabled]="isLoadSave">
                                    <div *ngIf="isLoadSave" class="spinner-border spinner-border-sm mr-1" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ng-template #elseSave>
                <div class="alert alert-info animated fadeIn my-5">
                    Se ha ingresado su solicitud con éxito.
                </div>
            </ng-template>
        </div>
        <ng-template #elseError>
            <div class="alert m-0 content-form mx-auto shadow rounded p-4" role="alert"
            [ngClass]="alert">
                {{ msgWarning }}
            </div>
        </ng-template>
    </div>
</section>